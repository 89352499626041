/**
 * @format
 */

import 'whatwg-fetch'
import * as toast from './toast'

window.formbutton =
  window.formbutton ||
  function() {
    ;(formbutton.q = formbutton.q || []).push(arguments)
  }

/* turning flask flash messages into js popup notifications */
window.popupMessages.forEach(function(m, i) {
  var category = m[0] === 'message' || !m[0] ? 'info' : m[0]
  var text = m[1]
  setTimeout(function() {
    toast[category](text)
  }, (1 + i) * 1500)
})

/* quick script for showing the resend confirmation form */
let resend = document.querySelector('a.resend')
if (resend) {
  resend.addEventListener('click', function(e) {
    e.preventDefault()

    resend.style.display = 'none'
    document.querySelector('form.resend').style.display = 'block'
  })
}

/* dismissable banner */
;(function(window) {
  var Storage = function() {
    function browserHasSupport() {
      var testItem = 'localStorageTest'
      if (window.localStorage) {
        try {
          window.localStorage.setItem(testItem, testItem)
          window.localStorage.removeItem(testItem)
          return true
        } catch (e) {
          return false
        }
      }
    }

    function deleteItem(item) {
      return window.localStorage.removeItem(item)
    }

    function getItem(item) {
      return window.localStorage.getItem(item)
    }

    function setItem(item, contents) {
      return window.localStorage.setItem(item, contents)
    }

    return {
      delete: deleteItem,
      enabled: browserHasSupport,
      get: getItem,
      set: setItem
    }
  }

  window.Storage = new Storage()
})(window)
;(function(window, Storage) {
  var storageEnabled = window.Storage.enabled()

  let banner = document.querySelector('.banner')
  if (!banner) return
  var expiry = banner.getAttribute('data-expiry'),
    id = banner.getAttribute('data-id')

  let hasExpiry = !!(id && expiry)

  if (hasExpiry && storageEnabled) {
    let timestamp = window.Storage.get(id)
    if (timestamp) {
      if (expiry === 'forever') {
        return
      } else {
        var now = new Date(),
          diffInHours = Math.floor(
            (now.getTime() - parseInt(timestamp)) / (1000 * 60 * 60)
          )
        if (diffInHours >= expiry) {
          // show the
          window.Storage.delete(id)
          banner.style.display = 'block'
        } else {
          return
        }
      }
    } else {
      // show the banner
      banner.style.display = 'block'
    }
  }

  banner.querySelector('.x').addEventListener('click', dismissBanner)

  function dismissBanner(event) {
    event.preventDefault()
    banner.style.display = 'none'
    if (hasExpiry && storageEnabled) {
      window.Storage.set(id, new Date().getTime())
    }
  }
})(window, Storage)
